/* MUST KEEP IN TS CONVERSION */
.muiTextfieldDisabledError .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: red!important;
}
.muiTextfieldDisabledError .MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-disabled {
    color: red!important;
}




/* HEADER COMPONENT */
.left-table-border {
border-left: 1px solid #E0E0E0;
}
.right-table-border {
    border-right: 1px solid #E0E0E0;
}
.top-table-border {
    border-top: 1px solid #E0E0E0;
}
.noLink {
    color: white;
    text-decoration: none;
    display: inline-block;
}
.header {
    background-color: #555555 !important;
    z-index: 1000;
}
#float-right {
float: right !important
}
.header svg {
    color: #fff;
}
.flex-end {
    justify-content: flex-end;
    list-style: none;
} 
.profile-dropdown svg {
    color: #555555;
}
.invoice-button {
    color: #9370DB;
    border: 1px solid #9370DB;
    background-color: transparent;
    padding: 5px 15px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
}
.invoice-button-filled {
    color: #fff;
    border: 1px solid #9370DB;
    background-color: #9370DB;
    padding: 5px 15px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
}
.invoice-button:hover {
    cursor: pointer;
    border: 1px solid #9370DB;
    background-color: #EDF3F9;
}
.invoice-button-filled:hover {
    cursor: pointer;
    border: 1px solid #9370DB;
    background-color: #9370DB;
    filter:drop-shadow(1px 1px 2px gray);
}
.invoice-textfield {
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: 1rem;
}
.invoice-textfield:focus {
    outline: none;
    border: none;
}
.legend-label {
    text-align: left;
    font-size: 0.75em;
}
.invoice-fieldset {
    border-radius: 4px;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.23);
}
.invoice-fieldset:focus-within {
    border: 2px solid #9370DB;
}

.l-dialog .MuiDialog-container .MuiPaper-root {
    max-width: 60%!important;
    width: 60%;
}

.l-dialog .MuiDialogContent-root .MuiPaper-root {
    max-width: 99%!important;
    width: 99%;
}

.s-dialog .MuiDialog-container .MuiPaper-root:first-child {
    max-width: 40%!important;
    width: 40%;
}

.m-dialog .MuiDialog-container .MuiPaper-root:first-child {
    max-width: 60%!important;
    width: 60%;
}

.s-dialog .MuiDialog-container .MuiPaper-root:first-child {
    max-width: 40%!important;
    width: 40%;
}

.xl-dialog .MuiDialog-container .MuiPaper-root {
    max-width: 80%!important;
    width: 80%;
}
.ee-dialog .MuiDialog-container .MuiPaper-root {
    max-width: 80%!important;
    width: 60%;
    height: 50%;
}
.paper-padding {
    padding: 20px;
}
.paper-padding-login {
    margin-top: 5%;
    padding: 20px 100px 70px 100px;
}

.MuiFormLabel-root.Mui-error {
    color: red!important;
}





/* LOGIN PAGE */
.login-screen {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* MAIN */
.content {
    flex-grow: 1;
    /* padding: 10px; */
}

/* #no-content {
    flex-grow: 1;
    padding: 0px!important;
} */

.main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#main {
    display: flex;
}

.toolbar {
    display: flex;
    align-items: center;
    margin-top: 60px;
    justify-content: "flex-end";
}

#white-icon .MuiSvgIcon-root {
    color: #fff;
}

#pink-icon .MuiSvgIcon-root {
    color: #9370DB;
}

#login-paper {
    width: 35%;
    text-align: center;
}

/* TABLES */
#table {
    width: 100%;
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

#onboarding-table {
    padding-top: 2%;
    width: 85%;
    text-align: center;
}

#table .row td {
    cursor: pointer;
}

#table .row {
    cursor: pointer;
}

#table .progress-bar .MuiLinearProgress-bar {
    width: 100%;
    background-color: #9370DB
}
.table-link {
    color: #9370DB;
    cursor: pointer;
}
#table-link {
    color: #9370DB;
    cursor: pointer;
}

#table-link2 a{
    color: #9370DB;
    cursor: pointer;
    text-decoration: none;
}
.padding-10 {
    border-radius: 10px;
    padding: 20px 20px 10px 20px;
}


/* VIEW PRODUCT */
.tab-panels {
    width: 80%;
}

.tab-panels h1 {
    text-align: center;
}

.view-product .MuiTableCell-root {
    padding: 10px;
}

.view-product .MuiFormControl-root {
    width: 100%;
}

.view-productt input {
    color: #000;
}

.view-product .MuiFormLabel-root {
    color: #000;
}

.view-product .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.54);
}

.view-product .pricing-text-field .MuiInputBase-input {
    text-align: center;
}

.view-product .pricing-field {
    text-align: center;
    background-color: transparent;
    border: none;
    color: black;
    width: 90%;
}

.view-product .voucher-field .MuiInputBase-input {
    text-align: center;
    background-color: transparent;
    border: none;
    color: black;
}

.view-product .faded-label .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.54);
}

/* HOME PAGE */
#home-page {
    width: 100%;
}

.login-tooltip {
    display: inline;
}

.xl-dialog .MuiDialog-container .MuiPaper-root {
    max-width: 80%!important;
    width: 80%;
}

.red-text {
    color: red
}

.coloured-hr {
    border-color: rgba(253, 253, 253, 0.5);
}

.bold-textfield .MuiInputBase-input {
    color:rgba(0, 0, 0, 0.3);
}

.normal-shaded.MuiTableRow-root.Mui-selected, .normal-shaded.MuiTableRow-root.Mui-selected:hover {
    background-color: rgba(102, 187, 106, 0.2);
}

.error-shaded.MuiTableRow-root.Mui-selected, .error-shaded.MuiTableRow-root.Mui-selected:hover {
    background-color: rgba(244, 67, 54, 0.2);
}

.chip-array {
    margin: 2px !important;
}

#myImage {
    position: relative;
    width: 100%;
}

#myImageHover {
    position: relative;
    width: 100%;
}

.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .3s ease;
    backface-visibility: hidden;
}

.hover-button {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

#myImageHover:hover .image {
    opacity: 0.3;
}

#myImageHover:hover .hover-button {
    opacity: 1;
    cursor: pointer;
}

.hover-button {
    background-color: rgba(147, 112, 219, 1);
    border-radius: 5px;
    padding: 6px 16px;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 0.875rem;
    z-index: 9999;
}

/* .MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-disabled{
    color: rgba(0, 0, 0, 0.38);
} */

#overide-tooltip, .MuiSpeedDialAction-staticTooltipLabel {
    width: max-content !important;
    z-index: 1000!important
}

#page-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#text-message-container div {
    overflow-y: scroll;
    height: 15rem;
    text-align: justify;
    padding-right: 1rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}